import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormLookingForDevelopersActions } from "../../../../Store/FormLookingForDevelopersSlice";

import "./FormLookingForDevelopers.css";



const FormLookingForDevelopers = () => {
  const dispatch = useDispatch();
  const Data = useSelector((state)=>state);
  const FormData = Data.FormLookingForDevelopers;
  const {ChangeName , ChangeEmail , ChangeNumber , ChangeGender , ChangeAge , ChangeGovernorates , ChangeWorkField , ChangeSkills , ChangeHobbies , ChangeAgree } = FormLookingForDevelopersActions ;

  const [language, setLanguage] = useState("en");

  const [selectedSkills, setSelectedSkills] = React.useState([]);
  const [proficiencyLevels, setProficiencyLevels] = React.useState({});
  
  const handleSkillChange = (event, skill) => {
    let updatedSkills;
    let updatedLevels = { ...proficiencyLevels };

    if (event.target.checked) {
      // إضافة المهارة عند التحديد
      updatedSkills = [...selectedSkills, skill];
  
      // إضافة مستوى الإتقان افتراضيًا (مثال: 50%)
      if (!(skill in updatedLevels)) {
        updatedLevels[skill] = 0; // Default proficiency level
      } 
  
    } else {
      // إزالة المهارة عند عدم التحديد
      updatedSkills = selectedSkills.filter((s) => s !== skill);
  
      // إزالة مستوى الإتقان عند حذف المهارة
      delete updatedLevels[skill];
    }
  
    setSelectedSkills(updatedSkills);
    setProficiencyLevels(updatedLevels);

  
    // تحديث Redux بالمهارات والمستويات المحدّثة
    dispatch(ChangeSkills(updatedLevels ));
  };
  
  const handleProficiencyChange = (event, skill) => {
    const newLevel = { ...proficiencyLevels, [skill]: event.target.value };
    setProficiencyLevels(newLevel);
  
    // تحديث Redux بالمستوى الجديد
    dispatch(ChangeSkills(  newLevel ));
  };

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "ar" : "en"));
  };

  const [agree, setAgree] = useState(false); // local state for agree

  const handleAgreeChange = (checked) => {
    setAgree(checked); // Update the agree state
  };


  function SendReq(event){
    let {Name , Email , Number , Gender , Age , Governorates , WorkField , Skills ,  Hobbies , Agree , Location , State }=FormData ;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "Name": Name,
    "Email": Email,
    "Number": parseInt(Number, 10),
    "Gender": Gender,
    "Age": parseInt(Age, 10),
    "Governorates": Governorates,
    "WorkField": WorkField,
    "Skills": JSON.stringify(Skills),
    "Hobbies": Hobbies,
    "Agree": Agree ? 1 : 0,
    "Location": Location,
    "State": State


    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    fetch("https://be.zakimax.com/api/SetDataFormLookingForDevelopers", requestOptions)
    .then(response => response.json())
    .then(data => {
        if(data.status === "OK"){
          console.log("OK , the Data is uploaded ")
          alert("Your data has been successfully submitted.");

        }
        else{
          console.log("You have error , Please try again ^_^ ")
          alert("Your data has been not successfully submitted.");

        }
    })
    // .then(
    //     setTimeout(()=>{
    //         window.location.reload(false)
    //     },100000)
    // )
    .catch(error => console.log('error', error));

}

  return (
    <div className={`FLFDContainer ${language === "ar" ? "rtl" : ""}`}>
      <div className="FLFDFormCard">
        <button className="FLFDLanguageToggle" onClick={toggleLanguage}>
          {language === "en" ? "العربية" : "English"}
        </button>
        <h2 className="FLFDFormTitle">
          {language === "en" ? "Looking For A Team Of Creative Developers (Volunteers)" : "البحث عن فريق مطورين مبدعين   (متطوعين)"}
        </h2>
        <form className="FLFDFormGroup" >
          <div className="FLFDInputContainer">
            <label>{language === "en" ? "Name" : "الاسم"}</label>
            <input  type="text" placeholder={language === "en" ? "Enter your name" : "أدخل اسمك"}  onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeName(e.target.value))  }/>
          </div>
          <div className="FLFDInputContainer">
            <label>{language === "en" ? "Email" : "البريد الإلكتروني"}</label>
            <input type="email" placeholder={language === "en" ? "Enter your email" : "أدخل بريدك الإلكتروني"}    onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeEmail(e.target.value))  }    />
          </div>
          <div className="FLFDInputContainer">
            <label>{language === "en" ? "Phone Number" : "رقم الهاتف"}</label>
            <input type="tel" placeholder={language === "en" ? "Enter your phone number" : "أدخل رقم هاتفك"} onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeNumber(e.target.value))  } />
          </div>
          <div className="FLFDInputContainer" >
            <label>{language === "en" ? "Gender" : "الجنس"}</label>
            <select onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeGender(e.target.value))  }>
              <option value="">{language === "en" ? "Select Gender" : "اختر الجنس"}</option>
              <option value="male">{language === "en" ? "Male" : "ذكر"}</option>
              <option value="female">{language === "en" ? "Female" : "أنثى"}</option>
            </select>
          </div>

          <div className="FLFDInputContainer">
            <label>{language === "en" ? "Age" : "العمر"}</label>
            <input type="number" placeholder={language === "en" ? "Enter your age" : "أدخل عمرك"}  onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeAge(e.target.value))  }/>
          </div>

          <div className="FLFDInputContainer" >
            <label>{language === "en" ? "Governorate" : "المحافظة"}</label>
            <select onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeGovernorates(e.target.value))  }>
              <option value="">{language === "en" ? "Select Governorate" : "اختر المحافظة"}</option>
              <option value="baghdad">{language === "en" ? "Baghdad" : "بغداد"}</option>
              <option value="basra">{language === "en" ? "Basra" : "البصرة"}</option>
              <option value="nineveh">{language === "en" ? "Nineveh" : "نينوى"}</option>
              <option value="erbil">{language === "en" ? "Erbil" : "أربيل"}</option>
              <option value="sulaymaniyah">{language === "en" ? "Sulaymaniyah" : "السليمانية"}</option>
              <option value="duhok">{language === "en" ? "Duhok" : "دهوك"}</option>
              <option value="kirkuk">{language === "en" ? "Kirkuk" : "كركوك"}</option>
              <option value="najaf">{language === "en" ? "Najaf" : "النجف"}</option>
              <option value="karbala">{language === "en" ? "Karbala" : "كربلاء"}</option>
              <option value="wasit">{language === "en" ? "Wasit" : "واسط"}</option>
              <option value="maysan">{language === "en" ? "Maysan" : "ميسان"}</option>
              <option value="dhiqar">{language === "en" ? "Dhi Qar" : "ذي قار"}</option>
              <option value="babil">{language === "en" ? "Babil" : "بابل"}</option>
              <option value="diwaniya">{language === "en" ? "Diwaniya" : "الديوانية"}</option>
              <option value="muthanna">{language === "en" ? "Muthanna" : "المثنى"}</option>
              <option value="anbar">{language === "en" ? "Anbar" : "الأنبار"}</option>
              <option value="saladin">{language === "en" ? "Saladin" : "صلاح الدين"}</option>
              <option value="diyala">{language === "en" ? "Diyala" : "ديالى"}</option>
            </select>
          </div>

          <div className="FLFDInputContainer">
            <label>{language === "en" ? "Field of Work" : "مجال العمل"}</label>
            <select onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeWorkField(e.target.value))  }>
              <option value="">{language === "en" ? "Select Field of Work" : "اختر مجال العمل"}</option>
              <option value="frontend">{language === "en" ? "Front-End Development" : "تطوير الواجهة الأمامية"}</option>
              <option value="backend">{language === "en" ? "Back-End Development" : "تطوير الواجهة الخلفية"}</option>
              <option value="fullstack">{language === "en" ? "Full-Stack Development" : "التطوير المتكامل"}</option>
              <option value="uiux">{language === "en" ? "UI/UX Design" : "تصميم تجربة المستخدم وواجهة المستخدم"}</option>
              <option value="mobile-development">{language === "en" ? "Mobile Development" : "تطوير تطبيقات الموبايل"}</option>
            </select>
          </div>


          <div className="FLFD-SkillsContainer">

            <label className="FLFDLabel">{language === "en" ? "Skills & Proficiency Level" : "المهارات ومستوى الإتقان"}</label>
            <p className="FLFDNote">{language === "en" ? "Select your skill and specify your proficiency level." : "حدد مهارتك واضبط مستوى إتقانك لها."}</p>
            <div className="FLFDCheckboxGroup">
              {[ 
                { value: "html", labelEn: "HTML", labelAr: "HTML" },
                { value: "css", labelEn: "CSS", labelAr: "CSS" },
                { value: "javascript", labelEn: "JavaScript", labelAr: "جافا سكريبت" },
                { value: "typescript", labelEn: "TypeScript", labelAr: "TypeScript" },
                { value: "react", labelEn: "React.js", labelAr: "React.js" },
                { value: "react_native", labelEn: "React Native", labelAr: "React Native" },
                { value: "flutter", labelEn: "Flutter", labelAr: "فلاتر" },
                { value: "node", labelEn: "Node.js", labelAr: "Node.js" },
                { value: "express", labelEn: "Express.js", labelAr: "Express.js" },
                { value: "php", labelEn: "PHP", labelAr: "PHP" },
                { value: "laravel", labelEn: "Laravel", labelAr: "لارافيل" },
                { value: "python", labelEn: "Python", labelAr: "بايثون" },
                { value: "django", labelEn: "Django", labelAr: "جانغو" },
                { value: "figma", labelEn: "Figma", labelAr: "فيجما" },
                { value: "adobe_xd", labelEn: "Adobe XD", labelAr: "أدوبي XD" },
                { value: "photoshop", labelEn: "Photoshop", labelAr: "فوتوشوب" }
              ].map((skill) => (
                <div key={skill.value} className="FLFDSkillContainer">
                  <label className="FLFDCheckbox">
                    <input 
                      type="checkbox" 
                      value={skill.value} 
                      onChange={(e) => handleSkillChange(e, skill.value)}
                    /> 
                    {language === "en" ? skill.labelEn : skill.labelAr}
                  </label>
                  {selectedSkills.includes(skill.value) && (
                    <div className="FLFDProficiency">
                      <label className="FLFDProficiencyLabel">
                        {language === "en" ? "Proficiency Level:" : "مستوى الإتقان:"}
                      </label>
                      <input 
                        type="range" 
                        min="0" 
                        max="100" 
                        step="5" 
                        value={proficiencyLevels[skill.value] || 0} 
                        onChange={(e) => handleProficiencyChange(e, skill.value)}
                      />
                      <span className="FLFDPercentage">{proficiencyLevels[skill.value] || 0}%</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>







          <div className="FLFDInputContainer">
            <label className="FLFDLabel">
              {language === "ar" ? "ما هي هواياتك؟" : "What are your hobbies?"}
            </label>
            <input
              type="text"
              className="FLFDInput"
              placeholder={language === "ar" ? "اكتب هواياتك هنا..." : "Write your hobbies here..."}
              onChange={(e) =>     dispatch(FormLookingForDevelopersActions.ChangeHobbies(e.target.value))  }
            />
          </div>

          <div className={`FLFDAgreeContainer ${language === "ar" ? "rtl" : "ltr"}`}>
            <input
              type="checkbox"
              id="agreeCheckbox"
              name="agree"
              checked={agree}
              onChange={(e) => { handleAgreeChange(e.target.checked) ;     dispatch(FormLookingForDevelopersActions.ChangeAgree(e.target.checked))  }}
            />
            <label htmlFor="agreeCheckbox">
              {language === "ar" ? "المال ليس هدفي" : "Money is not my goal"}
            </label>
          </div>





          <button type="submit" className="FLFDSubmitBtn" onClick={(e) => SendReq() } >
            {language === "en" ? "Submit An Application" : "تقديم الطلب"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormLookingForDevelopers;


