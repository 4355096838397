import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./FormRequests.css";



const FormRequests = () => {


const [Key, SetKey] = useState(0);
const [language, setLanguage] = useState("en");

const [Data, SetData] = useState([]);



// useEffect(() => {


//     if (Data.length > 0 && Data[Key]&& Data[Key].Skills) {
//         console.log('hih')
//         console.log(Data[Key].Skills)
//         let __skills = Data[Key].Skills
//         __skills = __skills === 'null'?'{}':__skills
//         SetSkills(JSON.parse(__skills || '{}'));
//         // SetSkills(Data[Key].Skills)
//     }

// }, [Key, Data]);

useEffect(()=>{
    GetData(); // Initial fetch
    const interval = setInterval(GetData, 15000); // Poll every 5 seconds
    return () => clearInterval(interval); // Cleanup on unmount

},[])
  

  

  

  

  

const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "ar" : "en"));
};

function ApproveTheApplication(event , Id){
    event.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "id": Id,

    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    fetch("https://be.zakimax.com/api/ApproveTheApplication", requestOptions)
    .then(response => response.json())
    .then(data => {
        if(data.status === "OK"){
          console.log("OK , the Data is uploaded ")
          console.log('the state changed')

        }
        else{
          console.log("You have error , Please try again ^_^ ")
          alert("Your data has been not successfully submitted.");

        }
    })

    .catch(error => console.log('error', error));

}



function GetData(event){
    // event.preventDefault();
    // let {Name , Email , Number , Gender , Age , Governorates , WorkField , Skills ,  Hobbies , Agree , Location , State }=FormData ;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "Content": 'All',

    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    fetch("https://be.zakimax.com/api/GetDataFormLookingForDevelopers", requestOptions)
    .then(response => response.json())
    .then(resp => {
        if(resp.status === "OK"){
          console.log("OK , the Data is uploaded ")
        //   console.log(resp)
          SetData(resp.data)

        }
        else{
          console.log("You have error , Please try again ^_^ ")
          alert("Your data has been not successfully submitted.");

        }
    })

    .catch(error => console.log('error', error));

}

function Counter(event, Status) {
    if (event) event.preventDefault();

    SetKey((prevKey) => {
      if (Status === "Increase" && prevKey < Data.length-1  ) return prevKey + 1;
      if (Status === "Decrease" && prevKey > 0 ) return prevKey - 1 ;
      return prevKey;
    });



  }

  return (
    <div className={`FRContainer ${language === "ar" ? "rtl" : ""}`}>
      <div className="FRFormCard">
        <button className="FRLanguageToggle" onClick={toggleLanguage}>
          {language === "en" ? "العربية" : "English"}
        </button>
        <h2 className="FRFormTitle">
          {language === "en" ? "All Request" : "جميع الطلبات"}
          <p>{Key + 1} of {Data.length}</p>
        </h2>
        <form className="FRFormGroup" >

            <div className="FRInputContainer">

            <label>{language === "en" ? "Id" : "الرقم التسلسلي"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Id : "No Data"}</p>
            
          </div>

          <div className="FRInputContainer">
            <label>{language === "en" ? "Name" : "الاسم"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Name : "No Data"}</p>
            
          </div>
          <div className="FRInputContainer">
            <label>{language === "en" ? "Email" : "البريد الإلكتروني"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Email : "No Data"}</p>
          </div>
          <div className="FRInputContainer">
            <label>{language === "en" ? "Phone Number" : "رقم الهاتف"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Number : "No Data"}</p>
          </div>
          <div className="FRInputContainer" >
            <label>{language === "en" ? "Gender" : "الجنس"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Gender : "No Data"}</p>
          </div>

          <div className="FRInputContainer">
            <label>{language === "en" ? "Age" : "العمر"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Age : "No Data"}</p>
          </div>

          <div className="FRInputContainer" >
            <label>{language === "en" ? "Governorate" : "المحافظة"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Governorates : "No Data"}</p>
          </div>

          <div className="FRInputContainer">
            <label>{language === "en" ? "Field of Work" : "مجال العمل"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].WorkField : "No Data"}</p>
          </div>


          <div className="FR-SkillsContainer">

            <label className="FRLabel">{language === "en" ? "Skills & Proficiency Level" : "المهارات ومستوى الإتقان"}</label>
            <div className="FRSkillContainer">

            {(Data.length > 0 && Data[Key]) ? (
                Object.entries((typeof Data[Key].Skills === 'string'&&Data[Key].Skills!=='null')?JSON.parse(Data[Key].Skills):{})
                .map(([skill, percentage],x) => (
                    percentage !== null && percentage !== undefined ? (
                        <div key={x}>
                            <strong>{skill}:</strong> {percentage}%
                        </div>
                    ) : <React.Fragment key={x}></React.Fragment>
                ))
            ) : <p>No Data</p>}

            </div>
          </div>







          <div className="FRInputContainer">
            <label className="FRLabel">
              {language === "ar" ? "ما هي هواياتك؟" : "What are your hobbies?"}
            </label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Hobbies : "No Data"}</p>

          </div>

          <div className="FRInputContainer">
            <label>{language === "en" ? "agree" : "الموافقة عللى شروط"}</label>
            <p>{Data.length > 0 && Data[Key] ?  Data[Key].Agree : "No Data"}</p>
          </div>

          <div className="FRInputContainer">
            <label>{language === "en" ? "State" : "الحالة"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].State : "No Data"}</p>
          </div>

          <div className="FRInputContainer">
            <label>{language === "en" ? "Location" : "الموقع"}</label>
            <p>{Data.length > 0 && Data[Key] ? Data[Key].Location : "No Data"}</p>
          </div>


          <button type="submit" className="FRSubmitBtn"  onClick={(e)=>Counter(e , 'Increase')} >
            {language === "en" ? "in" : "زيد"}
          </button>

          <button type="submit" className="FRSubmitBtn"  onClick={(e)=>Counter(e , 'Decrease')} >
            {language === "en" ? "de" : "نقص"}
          </button>



          <button type="submit" className="FRSubmitBtn"  onClick={(e)=>{ApproveTheApplication(e , Data[Key].Id) ; GetData() ;}} >
            {language === "en" ? "Approve The Application" : "وافق على الطلب"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormRequests;


