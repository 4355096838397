


import React, { useEffect } from 'react';

const Test = ({ isAuthenticated }) => {
  useEffect(() => {
    if (!isAuthenticated) {
      // Redirect after 5 seconds
      const timer = setTimeout(() => {
        window.location.href = "https://www.snapchat.com/add/zakimax3?share_id=ddE5Jp47cMA&locale=en-US";
      }, 3000);

      // Cleanup the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <div>Redirecting to Snapchat in 3 seconds...</div>;
  }

  return <div>Welcome to the protected page!</div>;
};

export default Test;
