import MainContener from "../../../../Layout/MainContener";
import "./AllRequests.css" ;
import FormRequests from "./FormRequests";



function AllRequests(){
    return(
        <MainContener>
            <div className="CoverAllRequests">
                <div className="AllRequestsText">
                    <p>All Requests</p>
                </div>


                 <div className="AllRequestsDiv">
                    <FormRequests />


                </div> 
                

            </div>
        </MainContener>

    );
};

export default AllRequests ;