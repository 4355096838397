import MainContener from "../../../../Layout/MainContener";
import FormLookingForDevelopers from "./FormLookingForDevelopers";
import "./LookingForDevelopers.css" ;



function LookingForDevelopers(){
    return(
        <MainContener>
            <div className="CoverLookingForDevelopers">
                <div className="LookingForDevelopersText">
                    <p>Search For Max's Team</p>
                </div>


                 <div className="LookingForDevelopersDiv">
                    <FormLookingForDevelopers />



                </div> 
                

            </div>
        </MainContener>

    );
};

export default LookingForDevelopers ;