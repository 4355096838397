import { createSlice } from "@reduxjs/toolkit";




const IS = {
    // FormId : 0,
    Name :"",
    Email :"",
    Number :"",
    Gender:"",
    Age:0,
    Governorates : "" ,
    WorkField : "" ,
    Skills : {} ,
    Hobbies : "" ,
    Agree : 0 ,
    Location : "T" ,
    State: 0 
};



const FormLookingForDevelopersSlice = createSlice({
    name:"FormLookingForDevelopers",
    initialState: IS , 
    reducers :{
 
        ChangeName :(state, action)=>{
            state.Name=action.payload ;
            return state ;
        },
        ChangeEmail :(state, action)=>{
            state.Email=action.payload ;
            return state ;
        },
        ChangeNumber: (state, action) => {
            state.Number = action.payload;
        },
        ChangeGender: (state, action) => {
            state.Gender = action.payload;
        },
        ChangeAge: (state, action) => {
            state.Age = action.payload;
        },
        ChangeGovernorates: (state, action) => {
            state.Governorates = action.payload;
        },
        ChangeWorkField: (state, action) => {
            state.WorkField = action.payload;
        },
        ChangeSkills: (state, action) => {
            state.Skills = action.payload;
        },
        ChangeHobbies: (state, action) => {
            state.Hobbies = action.payload;
        },
        ChangeAgree: (state, action) => {
            state.Agree = action.payload;
        },
        ChangeLocation: (state, action) => {
            state.Location = action.payload;
        },
        ChangeState: (state, action) => {
            state.State = action.payload;
        },



    }
})


export const FormLookingForDevelopersActions = FormLookingForDevelopersSlice.actions ;


export default FormLookingForDevelopersSlice.reducer ;